// Este es para mi red interna para verlo desde el celular
// Hay que permitir los CORS en la api http://192.168.100.*:*
// const endpoint_url_dev = 'http://192.168.100.12:8000';

const endpoint_url_dev = 'http://localhost:8000';
const endpoint_url_prod = 'https://api.bscbi.cl';

let endpoint_url = endpoint_url_prod
let buildenv = 'T'
if (process.env.NODE_ENV === 'development') {
    // do something in development mode
    buildenv = 'D';
    endpoint_url = endpoint_url_dev;
} else if (process.env.NODE_ENV === 'production') {
    // do something in production mode
    buildenv = 'P'
}

  
export const endpointDomain = endpoint_url;
export const appbuild = '20241211-26.0.0' + buildenv;

